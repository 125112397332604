import React, {
  FormEventHandler,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Button from '../components/Button';
import Layout from '../components/Layout';
import NotificationCard from '../components/NotificationCard';
import TabCard from '../components/TabCard';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { request } from '../utils/request';
import { pDelay } from '../utils/pDelay';
import DeleteNotificationModal from '../components/DeleteNotificationModal';
import CreateInternalNotificationModal from '../components/CreateInternalNotificationModal';
import EditInternalNotificationModal from '../components/EditInternalNotificationModal';
import InternalNotificationModal from '../components/InternalNotificationModal';
import NotificationActions from '../components/NotificationActions';
import { Notification, UserFM } from '@bm-js/h2o-shared';
import { ActionType } from '../types/dispatch.types';

const NotificationsPage = () => {
  const state = useContext(GlobalStateContext);
  const stateUser = state.user as UserFM;
  const dispatch = useContext(GlobalDispatchContext);

  const [notifications, setNotifications] = useState<{
    my: { active: Notification[]; archived: Notification[] };
    other: { active: Notification[]; archived: Notification[] };
  }>({
    my: { active: [], archived: [] },
    other: { active: [], archived: [] },
  });

  const [myNotificationsActiveTab, setMyNotificationsActiveTab] = useState(0);
  const [otherNotificationsActiveTab, setOtherNotificationsActiveTab] =
    useState(0);

  const [allUsers, setAllUsers] = useState<UserFM[]>([]);

  const initialInternalNotification = {
    isInternal: true,
    subject: '',
    recipientId: '',
    recipientFirstName: '',
    recipientLastName: '',
    dateForHandling: '',
    textContent: '',
  };

  const [newInternalNotification, setNewInternalNotification] = useState({
    active: false,
    notification: initialInternalNotification,
  });
  const [editInternalNotification, setEditInternalNotification] = useState<{
    active: boolean;
    notification: {} | Notification;
  }>({
    active: false,
    notification: {},
  });

  const [leftCardContents, setLeftCardContents] = useState<ReactNode[]>([]);
  const [rightCardContents, setRightCardContents] = useState<ReactNode[]>([]);

  const [batchSelection, setBatchSelection] = useState<Notification[]>([]);

  const [deleteNotificationPending, setDeleteNotificationPending] = useState<{
    active: boolean;
    notification: {} | Notification;
  }>({
    active: false,
    notification: {},
  });

  const [viewInternalNotificationModal, setViewInternalNotificationModal] =
    useState({
      active: false,
      notification: {},
    });

  const getAllUsers = async () => {
    const { data } = await request<UserFM[]>({
      state,
      dispatch,
      path: 'users/auth/all-users/minimal',
      skipLoader: true,
    });

    if (!data) return;
    const filteredUsers = data.filter((u) => {
      if (u.firstName === 'Patrik' && u.lastName === 'Hellberg') return false;
      else if (u.firstName === 'Bucket' && u.lastName === 'Media') return false;
      else return true;
    });
    setAllUsers(filteredUsers);
  };

  const getNotifications = async () => {
    const { data } = await request<{
      my: { active: Notification[]; archived: Notification[] };
      other: { active: Notification[]; archived: Notification[] };
    }>({
      state,
      dispatch,
      path: `notifications/get/${stateUser._id}`,
    });
    if (!data) return;
    parseNotifications(data);
  };

  const createInternalNotification: FormEventHandler = async (e) => {
    e.preventDefault();
    dispatch({ type: ActionType.LOADING, value: true });
    const body = { ...newInternalNotification.notification };
    if (body.recipientId !== 'allUsers') {
      body.recipientLastName = allUsers.filter(
        (u) => u._id === body.recipientId
      )[0].lastName;
      body.recipientFirstName = allUsers.filter(
        (u) => u._id === body.recipientId
      )[0].firstName;
    }

    const { err } = await request({
      state,
      dispatch,
      body,
      successText: 'Notis sparad',
      method: 'POST',
      path: `notifications/add-internal-notification/${body.recipientId}`,
    });

    if (err) return;

    await pDelay(1000);
    setNewInternalNotification({
      active: false,
      notification: initialInternalNotification,
    });
    getNotifications();
  };

  const parseNotifications = (data: {
    other: {
      active: Notification[];
      archived: Notification[];
    };
    my: {
      active: Notification[];
      archived: Notification[];
    };
  }) => {
    const filteredOtherActive = data.other.active.sort((a, b) => {
      const dateA = new Date(a.dateForHandling);
      const dateB = new Date(b.dateForHandling);
      return dateA.getTime() - dateB.getTime();
    });
    const filteredOtherArchived = data.other.archived.sort((a, b) => {
      const dateA = new Date(a.dateForHandling);
      const dateB = new Date(b.dateForHandling);
      return dateA.getTime() - dateB.getTime();
    });
    const filteredMyActive = data.my.active.sort((a, b) => {
      const dateA = new Date(a.dateForHandling);
      const dateB = new Date(b.dateForHandling);
      return dateA.getTime() - dateB.getTime();
    });
    const filteredMyArchived = data.my.archived.sort((a, b) => {
      const dateA = new Date(a.dateForHandling);
      const dateB = new Date(b.dateForHandling);
      return dateA.getTime() - dateB.getTime();
    });

    const dataToSet = {
      my: {
        active: filteredMyActive,
        archived: filteredMyArchived,
      },
      other: {
        active: filteredOtherActive,
        archived: filteredOtherArchived,
      },
    };
    setNotifications(dataToSet);

    dispatch({ type: ActionType.LOADING, value: false });
  };

  const archiveNotification = async (notification: Notification) => {
    dispatch({ type: ActionType.LOADING, value: true });

    const { err } = await request({
      method: 'POST',
      state,
      dispatch,
      path: `notifications/archive/${notification.recipientId}/${notification._id}`,
      successText: 'Notis arkiverad',
    });

    if (err) return;
    getNotifications();
  };

  const deleteNotification = async (notification: Notification) => {
    const { err } = await request({
      method: 'DELETE',
      state,
      dispatch,
      path: `notifications/delete/${notification.recipientId}/${notification._id}`,
      successText: 'Notis raderad',
    });
    if (err) return;
    setDeleteNotificationPending({
      ...deleteNotificationPending,
      active: false,
    });
    getNotifications();
  };

  const preDeleteNotification = (notification: Notification) => {
    setDeleteNotificationPending({
      active: true,
      notification: notification,
    });
  };

  const showInternalNotification = (notification: Notification) => {
    setViewInternalNotificationModal({
      active: true,
      notification,
    });
  };

  const editNotificationInit = (notification: Notification) => {
    setEditInternalNotification({
      active: true,
      notification,
    });
  };

  const updateInternalNotification: FormEventHandler = async (e) => {
    e.preventDefault();
    const { recipientId, _id } =
      editInternalNotification.notification as Notification;

    const { err } = await request({
      method: 'POST',
      state,
      dispatch,
      path: `notifications/edit-internal-notification/${recipientId}/${_id}`,
      successText: 'Notis uppdaterad',
      body: editInternalNotification.notification,
    });

    if (err) return;
    setEditInternalNotification({
      ...editInternalNotification,
      active: false,
    });
    getNotifications();
  };

  const toggleBatchSelection = (notification: Notification) => {
    const updated = [...batchSelection];
    const index = batchSelection.findIndex((n) => n._id === notification._id);
    if (index === -1) {
      updated.push(notification);
    } else {
      updated.splice(index, 1);
    }
    setBatchSelection(updated);
  };

  useEffect(() => {
    setLeftCardContents([
      notifications.my.active.map((notification) => (
        <NotificationCard
          batchSelection={batchSelection}
          toggleBatchSelection={toggleBatchSelection}
          notification={notification}
          key={notification._id}
          my
          editNotification={() => editNotificationInit(notification)}
          hasEdit={notification.isInternal && stateUser.role === 'Admin'}
          showInternalNotification={showInternalNotification}
          preDeleteNotification={preDeleteNotification}
          archiveNotification={archiveNotification}
        />
      )),
      notifications.my.archived.map((notification) => (
        <NotificationCard
          batchSelection={batchSelection}
          toggleBatchSelection={toggleBatchSelection}
          notification={notification}
          key={notification._id}
          my
          editNotification={() => editNotificationInit(notification)}
          hasEdit={notification.isInternal && stateUser.role === 'Admin'}
          showInternalNotification={showInternalNotification}
          preDeleteNotification={preDeleteNotification}
          archived
        />
      )),
    ]);
    setRightCardContents([
      notifications.other.active.map((notification) => (
        <NotificationCard
          batchSelection={batchSelection}
          toggleBatchSelection={toggleBatchSelection}
          my={stateUser.role === 'Admin'}
          notification={notification}
          key={notification._id}
          editNotification={() => editNotificationInit(notification)}
          hasEdit={notification.isInternal && stateUser.role === 'Admin'}
          showInternalNotification={showInternalNotification}
          preDeleteNotification={preDeleteNotification}
          archiveNotification={archiveNotification}
        />
      )),
      notifications.other.archived.map((notification) => (
        <NotificationCard
          batchSelection={batchSelection}
          toggleBatchSelection={toggleBatchSelection}
          notification={notification}
          key={notification._id}
          editNotification={() => editNotificationInit(notification)}
          hasEdit={notification.isInternal && stateUser.role === 'Admin'}
          showInternalNotification={showInternalNotification}
          my={stateUser.role === 'Admin'}
          preDeleteNotification={preDeleteNotification}
          archived
        />
      )),
    ]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, batchSelection]);

  useEffect(() => {
    dispatch({ type: ActionType.LOADING, value: true });
    if (state.token) {
      getNotifications();
      getAllUsers();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token]);

  return (
    <Layout>
      <div data-test="notifications-page">
        <h1 className="page-header">Notiser</h1>
        <Breadcrumbs
          crumbs={[{ to: '/home', p: 'Hem' }, { p: 'Notiser' }]}
          rightContent={
            <Button
              style={{ whiteSpace: 'nowrap' }}
              small
              onClick={() =>
                setNewInternalNotification({
                  ...newInternalNotification,
                  active: true,
                })
              }
            >
              Skapa intern notis
            </Button>
          }
        />
        <NotificationActions
          batchSelection={batchSelection}
          setBatchSelection={setBatchSelection}
          revalidate={getNotifications}
        />
        <div className="single-customer-inner">
          <div className="single-customer-left-content">
            <TabCard
              activeTab={myNotificationsActiveTab}
              setActiveTab={setMyNotificationsActiveTab}
              tabs={['Mina notiser', 'Mina arkiverade notiser']}
              contents={leftCardContents}
            />
          </div>
          <div className="single-customer-right-content">
            <TabCard
              activeTab={otherNotificationsActiveTab}
              setActiveTab={setOtherNotificationsActiveTab}
              tabs={['Andras notiser', 'Andras arkiverade notiser']}
              contents={rightCardContents}
            />
          </div>
        </div>
        <DeleteNotificationModal
          deleteNotification={deleteNotification}
          // @ts-ignore
          deleteNotificationPending={deleteNotificationPending}
          setDeleteNotificationPending={setDeleteNotificationPending}
        />
        <CreateInternalNotificationModal
          newInternalNotification={newInternalNotification}
          setNewInternalNotification={setNewInternalNotification}
          createInternalNotification={createInternalNotification}
          allUsers={allUsers}
        />
        <EditInternalNotificationModal
          // @ts-ignore
          editInternalNotification={editInternalNotification}
          setEditInternalNotification={setEditInternalNotification}
          updateInternalNotification={updateInternalNotification}
          allUsers={allUsers}
        />
        <InternalNotificationModal
          // @ts-ignore
          viewInternalNotificationModal={viewInternalNotificationModal}
          setViewInternalNotificationModal={setViewInternalNotificationModal}
        />
      </div>
    </Layout>
  );
};

export default NotificationsPage;
