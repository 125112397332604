import React, { FormEventHandler } from 'react';
import OverlayBox from './OverlayBox';
import DateInput from './DateInput';
import Button from './Button';
import { UserFM } from '@bm-js/h2o-shared';

type InternalNotification = any;

type Props = {
  newInternalNotification: InternalNotification;
  setNewInternalNotification: (v: InternalNotification) => void;
  createInternalNotification: FormEventHandler;
  allUsers: UserFM[];
};

const CreateInternalNotificationModal = ({
  newInternalNotification,
  setNewInternalNotification,
  createInternalNotification,
  allUsers,
}: Props) => {
  return (
    <OverlayBox
      active={newInternalNotification.active}
      close={() =>
        setNewInternalNotification({
          ...newInternalNotification,
          active: false,
        })
      }
      header="Skapa intern notis"
    >
      <form onSubmit={createInternalNotification}>
        <p>Mottagare</p>
        {/* eslint-disable-next-line */}
        <select
          value={newInternalNotification.notification.recipientId}
          name="recipientId"
          onChange={(e) => {
            setNewInternalNotification({
              ...newInternalNotification,
              notification: {
                ...newInternalNotification.notification,
                [e.target.name]: e.target.value,
              },
            });
          }}
        >
          <option disabled value="">
            Välj mottagare
          </option>
          {allUsers.map((user) => (
            <option key={user._id} value={user._id}>
              {user.firstName} {user.lastName}
            </option>
          ))}
          <option value="allUsers">Alla</option>
        </select>
        <p>Rubrik</p>
        <input
          type="text"
          value={newInternalNotification.notification.subject}
          name="subject"
          placeholder="Rubrik"
          onChange={(e) => {
            setNewInternalNotification({
              ...newInternalNotification,
              notification: {
                ...newInternalNotification.notification,
                [e.target.name]: e.target.value,
              },
            });
          }}
        />
        <p>Datum</p>
        <DateInput
          value={newInternalNotification.notification.dateForHandling}
          name="dateForHandling"
          placeholder="Rubrik"
          onChange={(e) => {
            setNewInternalNotification({
              ...newInternalNotification,
              notification: {
                ...newInternalNotification.notification,
                [e.target.name]: e.target.value,
              },
            });
          }}
        />
        <p>Notistext</p>
        <textarea
          value={newInternalNotification.notification.textContent}
          name="textContent"
          placeholder="Rubrik"
          onChange={(e) => {
            setNewInternalNotification({
              ...newInternalNotification,
              notification: {
                ...newInternalNotification.notification,
                [e.target.name]: e.target.value,
              },
            });
          }}
        />
        <Button med type="submit">
          Spara
        </Button>
      </form>
    </OverlayBox>
  );
};

export default CreateInternalNotificationModal;
