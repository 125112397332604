import React, { FormEventHandler } from 'react';
import OverlayBox from './OverlayBox';
import DateInput from './DateInput';
import Button from './Button';
import { Notification, UserFM } from '@bm-js/h2o-shared';

type EditInternalNotification = {
  notification: Notification;
  active: boolean;
};

type Props = {
  editInternalNotification: EditInternalNotification;
  setEditInternalNotification: (v: EditInternalNotification) => void;
  updateInternalNotification: FormEventHandler;
  allUsers: UserFM[];
};

const EditInternalNotificationModal = ({
  editInternalNotification,
  setEditInternalNotification,
  updateInternalNotification,
  allUsers,
}: Props) => {
  return (
    <OverlayBox
      active={editInternalNotification.active}
      close={() =>
        setEditInternalNotification({
          ...editInternalNotification,
          active: false,
        })
      }
      header="Skapa intern notis"
    >
      <form onSubmit={updateInternalNotification}>
        <p>Mottagare</p>
        {/* eslint-disable-next-line */}
        <select
          value={editInternalNotification.notification.recipientId}
          name="recipientId"
          onChange={(e) => {
            setEditInternalNotification({
              ...editInternalNotification,
              notification: {
                ...editInternalNotification.notification,
                [e.target.name]: e.target.value,
              },
            });
          }}
        >
          <option disabled value="">
            Välj mottagare
          </option>
          {allUsers.map((user) => (
            <option key={user._id} value={user._id}>
              {user.firstName} {user.lastName}
            </option>
          ))}
          <option value="allUsers">Alla</option>
        </select>
        <p>Rubrik</p>
        <input
          type="text"
          value={editInternalNotification.notification.subject}
          name="subject"
          placeholder="Rubrik"
          onChange={(e) => {
            setEditInternalNotification({
              ...editInternalNotification,
              notification: {
                ...editInternalNotification.notification,
                [e.target.name]: e.target.value,
              },
            });
          }}
        />
        <p>Datum</p>
        <DateInput
          value={editInternalNotification.notification.dateForHandling}
          name="dateForHandling"
          placeholder="Rubrik"
          onChange={(e) => {
            setEditInternalNotification({
              ...editInternalNotification,
              notification: {
                ...editInternalNotification.notification,
                [e.target.name]: e.target.value,
              },
            });
          }}
        />
        <p>Notistext</p>
        <textarea
          value={editInternalNotification.notification.textContent}
          name="textContent"
          placeholder="Rubrik"
          onChange={(e) => {
            setEditInternalNotification({
              ...editInternalNotification,
              notification: {
                ...editInternalNotification.notification,
                [e.target.name]: e.target.value,
              },
            });
          }}
        />
        <Button med type="submit">
          Spara
        </Button>
      </form>
    </OverlayBox>
  );
};

export default EditInternalNotificationModal;
