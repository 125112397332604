import React from 'react';
import styled from 'styled-components';
import { colors } from '../utils/siteVars';

const StyledCheckbox = styled.div`
  position: relative;
  height: 15px;
  input {
    display: none;
  }
  label {
    cursor: pointer;
    &:after {
      position: absolute;
      inset: 0;
      border-radius: 4px;
      background: ${colors.white};
      content: '';
      border: 1px solid ${colors.mediumGrey};
      width: 15px;
      height: 15px;
    }
  }
  &.checked label:after {
    background: ${colors.lightBlue};
  }
`;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const Checkbox = (props: Props) => {
  const { id, label, ...rest } = props;

  return (
    <StyledCheckbox className={props.checked ? 'checked' : ''}>
      <label htmlFor={props.id}>{!!label && <span>{label}</span>}</label>
      <input {...rest} id={props.id} type="checkbox" />
    </StyledCheckbox>
  );
};

export default Checkbox;
