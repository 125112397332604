import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../utils/siteVars';
import BtnNoStyle from './BtnNoStyle';
import Card from './Card';

const StyledTabCard = styled.div`
  margin-top: -0.5rem;
  .single-customer-card-headers {
    display: flex;
    button {
      background: ${colors.lightGrey};
      color: ${colors.darkGrey};
      margin-top: 0.5rem;
      box-shadow: 2px -2px 8px rgba(0, 0, 0, 0.05);
      padding: 1rem;
      font-weight: 500;
      font-size: 1.1rem;
      transition: 100ms ease-in-out;
      border-radius: 15px 15px 0 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: none;
      border-left: 0;
      &:hover,
      &.active {
        color: ${colors.lightBlue};
      }
      &.active {
        border-color: rgba(0, 0, 0, 0.1);
        background: ${colors.white};
      }
    }
  }
  .tab-card {
    border-radius: 0 10px 10px 10px;
  }
  .mobile-tabs {
    display: none;
  }
  @media (max-width: 800px) {
    .single-customer-card-headers {
      display: none;
    }
    .tab-card {
      border-radius: 0 0 10px 10px;
    }

    .mobile-tabs {
      display: flex;
      select.mobile-tabs-select {
        margin-bottom: 0;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 10px 10px 0 0;
        border-bottom: none;
      }
    }
  }
`;

type Props = {
  tabs: string[];
  contents: ReactNode[];
  slots?: ReactNode[];
  activeTab: number;
  setActiveTab: (index: number) => void;
};

const TabCard = (props: Props) => {
  const { tabs, contents, slots, activeTab, setActiveTab } = props;
  return (
    <StyledTabCard>
      <div className="single-customer-card-headers">
        {tabs.map((tab, index) => (
          <BtnNoStyle
            key={tab}
            onClick={() => setActiveTab(index)}
            className={activeTab === index ? 'active' : ''}
          >
            {tab}
          </BtnNoStyle>
        ))}
      </div>
      <div className="mobile-tabs">
        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
        <select
          className="mobile-tabs-select"
          onChange={(e) => {
            setActiveTab(Number(e.target.value));
          }}
        >
          {tabs.map((tab, index) => (
            <option key={tab} value={index}>
              {tab}
            </option>
          ))}
        </select>
      </div>
      <Card className="tab-card">
        {slots?.[activeTab]}
        {contents[activeTab]}
      </Card>
    </StyledTabCard>
  );
};

export default TabCard;
